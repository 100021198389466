body {
 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 800px) {
  .main-container {
    flex-direction: column;
  }
}
.richaai {
  color: red;
  font-size: 100px;
}
.logo {
  animation: fadeIn;
  animation-duration: 10s;
  align-content: center;
  /* border-radius: 25px; */
  
}
.coming-soon {
  animation: moveIn;
  animation-duration: 5s;
  
  color: white;
  font-size: 70px;
  justify-content: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

@keyframes moveIn {
  0%{
    opacity: 0;
    transform: translateY(-100p);
  }
  50%{
    opacity: .7;
    transform: translateY(-50px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px)
  }
}

@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}